import React from 'react'
import { Table } from 'semantic-ui-react'

import Layout from '../components/Layout'
import SEO from '../components/SEO'

const warrantyInfo = [
  {
    item: 'Air Pumps For Disposable Jet-liner',
    period: 'Five (5) Years'
  },
  {
    item: 'Basin',
    period: 'Two (2) Years'
  },
  {
    item: 'Other Components/Parts',
    period: 'One (1) Year'
  },
  {
    item: 'Accessories',
    period: 'One (1) Year'
  }
]

const LimitedWarranty = () => {
  return (
    <Layout>
      <SEO
        title="Limited Warranty"
        description="Contego Limited Warranty Policy"
        meta={[
          {
            name: 'keywords',
            content:
              'Nail salon design,Nail salon startup cost,Pedicure spa Massage chair,Contego,Contego spa,Contego spa designs,Contego spa designs inc,Contego spa liner,Contego spa chair review,Contego spa reviews,Contego spa pedicure,Contego spa pedicure chair,Contego pedicure chairs price,Pedicure chair,Pedicure spa,Spa chair,Design ideas for nail salon,Luxury pedicure chairs,Cheap pedicure chairs,Pedicure spa Massage chairs for sale,Cheap spa pedicure chairs,pedicure spa Massage chair package deal,Contego pedicure chairs,Pedicure chairs,Pedicure chairs for sale,Pedicure chairs with massage,Pedicure chairs cheap,Pedicure chairs parts,Pedicure chairs wholesale,Pedicure chair ideas,Pedicure chair for sale,Spa pedicure chairs,Nail salon pedicure chair,Nail Salon Furniture,Nail Salon Equipment,Pedi spa chair,Spa pedicure chairs,Salon Equipment,Pedicure chair parts,Pedicure furniture'
          }
        ]}
      />
      <div className="container py-4">
        <div className="row py-5">
          <div className="col">
            <h1 className="w-100 text-center">LIMITED WARRANTY</h1>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <p>
              CONTÉGO Spa Designs, Inc. (“Company”, “we”, “us” or “our”), provides you, the original
              retail purchaser with the limited warranty on our Pedicure Spa Massage Chairs as
              described herein. This limited warranty applies to our Pedicure Spa Massage Chairs
              properly installed at a location within the United States and only under conditions of
              normal, indoor commercial use, subject to the limitations and exclusions described
              below.
            </p>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <h3>LIMITED WARRANTY COVERAGE</h3>
            <p>
              We warrant to you, the original owner of the Pedicure Spa Massage Chairs, that our
              products will be free from material defects in workmanship, materials, and
              construction from date of shipment for the number of years noted below when used for
              normal, indoor commercial use.
            </p>

            <Table celled stackable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>ITEM</Table.HeaderCell>
                  <Table.HeaderCell>
                    WARRANTY PERIOD<sup>*</sup>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {warrantyInfo.map(element => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <p>{element.item}</p>
                      </Table.Cell>
                      <Table.Cell>
                        <p>{element.period}</p>
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>

              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell colSpan="2">
                    <p>
                      <sup>*</sup>Shipping Costs – During the first year after the date of shipment,
                      we will cover normal shipping costs associated with replacement parts;
                      expedited shipping costs will incur an additional charge.
                    </p>
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <h3>WARRANTY SERVICE</h3>
            <p>
              If you have an issue with your product that you feel is covered by our limited
              warranty, contact us by email at{' '}
              <a href="mailto:warranty@contegospa.com">warranty@contegospa.com</a> or by written
              submission to: <strong>12856 Brookhurst St Garden Grove, CA 92840</strong>. You will
              need your dated sales receipt to initiate your warranty claim. The warranty period
              begins on the date of shipment of your product. If you live outside of our delivery
              service area, then you may be responsible for paying service fees or you may be
              required to return the product to our closest service department. The sole remedy
              provided by us under this warranty will be to repair or replace product or any part
              thereof. We reserve the right to decide the appropriate remedy at our sole discretion.
              If we decide to replace a product we no longer sell, we will replace it with a
              comparable product of equal or lesser value which is of like kind, age, and quality.
              No refunds are permitted. If your product is repaired or replaced, the limited
              warranty will not be renewed or extended.
            </p>

            <p>
              <strong>
                The remedies provided herein are exclusive and, to the extent allowed by applicable
                law, are in lieu of all other remedies, warranties, or representations; express,
                implied, or statutory; including but not limited to any implied warranty of
                merchantability or fitness for a particular purpose and all other obligations and
                liabilities, whether in contract or in tort, all of which are expressly disclaimed
                herein. In no event shall Contego Spa Designs, Inc., be liable for special,
                consequential, incidental, or other damages even if advised of the possibility
                thereof.
              </strong>
            </p>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <h3>YOUR RIGHTS</h3>
            <p>
              This limited warranty gives you specific legal rights, and you may also have other
              rights, which vary from state to state. Some states do not allow the exclusions and
              limitations on incidental and consequential damages or the limitations on implied
              warranties, so the described limitations may not apply to you. However, proper care
              and use are essential for preserving your rights under this limited warranty.
            </p>
          </div>
        </div>

        <div className="row flex-column py-2 mb-2">
          <div className="col">
            <h3>WARRANTY LIMITATIONS AND EXCLUSIONS</h3>
            <p>
              Our products are not warrantied against normal wear and tear; noncommercial use;
              accidents, abuse, or neglect; or from damage resulting from improper use, care, or
              storage or noncompliance with the applicable user manual. Our warranty does not cover
              products used in residential, noncommercial settings. Because of the natural
              variations in leather and the finishing techniques used to create our spa chairs,
              finish colors may vary from piece to piece. Our limited warranty does not cover such
              variations or any differences between floor samples, swatches, or printed
              illustrations and your item(s). We are not responsible for color variations in leather
              upholstery due to dye lot variations that may occur with nonconcurrent orders. Our
              limited warranty does not cover products sold “as is,” purchased from a source other
              than us, used in a residential or other noncommercial setting, or shipped outside the
              contiguous U.S. by you or a freight forwarder. Any product repair or modification by
              you or any other unauthorized source, or use of third-party parts, voids this
              warranty. No distributor, dealer or other person has the right or authority to change
              this limited warranty or otherwise bind us.
            </p>
            <br />
            <p>
              In addition, this limited warranty also does not cover damage due to freight,
              accident, natural disaster and/or abuse; staining, fading or discoloration of the
              seat, seat back, armrests or pivoting leg supports; fading, discoloration or staining
              of any part due to improper water additives or harmful cleaning agents or chemicals;
              damage to drain pump due to obstruction in drain hose; or damage or malfunctions due
              to improper installation including but not limited to, failure to comply with all
              national, state and local plumbing, electrical and building codes.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default LimitedWarranty
